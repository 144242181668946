import { ShoppingBagIcon } from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const ProductListPage = lazy(() => import('../pages/PaymentsMethodsList'));
const EditProductPage = lazy(() => import('../pages/EditPaymentMethod'));
const AddProductPage = lazy(() => import('../pages/AddPaymentMethods'));

export const routes: AppRoute[] = [
	{
		id: pageIds.paymentsMethods,
		name: 'Metodos Pago',
		path: '/payments',
		icon: <ShoppingBagIcon className={'text-light-primary-green w-6 h-6'} />,
		page: ProductListPage,
		breadcrumb: ['PaymentMethods'],
		showInMenu: false,
		isPrivate: true,
	},
	{
		id: 'EditProduct',
		name: 'Edit Payment Method',
		path: '/payments/edit/:id',
		icon: <ShoppingBagIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditProductPage,
		breadcrumb: ['PaymentMethods', 'Edit Product'],
		isPrivate: true,
	},
	{
		id: 'AddProduct',
		name: 'Add Payment Method',
		path: '/payments/add',
		icon: <ShoppingBagIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddProductPage,
		breadcrumb: ['PaymentMethods', 'Add payment'],
		isPrivate: true,
	},
];
