export const AppModules: string[] = [
	'Authentication',
	'Dashboard',
	'Clients',
	'User',
	'Inventory',
	'Bussines',
	'Banks',
	'Budgets',
	'Items',
	'BudgetsFND',
	'Invoices',
	'Pdf',
	'Purchases',
	'NotFound',
	'Impimur',
	'PaymentsMethods',
	'ProForms',
	'Logistic',
	'PubliApp',
	// NotFound always at last
];
