import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { Client } from 'redux/slices/clients/type';
import { baseConfig } from '../../../config/http';

// We can move endpoint to another files
export const clientsApi = createApi({
	reducerPath: 'clients-api',
	baseQuery: fetchBaseQuery({
		...baseConfig,
	}),
	endpoints: builder => ({
		getClients: builder.mutation({
			query: () => ({
				url: `/api/clients`,
				method: 'GET',
			}),
		}),
		getClientsById: builder.mutation({
			query: (id: string) => ({
				url: `/api/clients/${id}`,
				method: 'GET',
			}),
		}),
		updateClient: builder.mutation({
			query: (client: Client) => ({
				url: `/api/clients/`,
				method: 'PATCH',
				body: client,
			}),
		}),
		createClient: builder.mutation({
			query: (client: Client) => ({
				url: `/api/clients/`,
				method: 'POST',
				body: client,
			}),
		}),
	}),
});

export const { useGetClientsMutation, useGetClientsByIdMutation, useUpdateClientMutation, useCreateClientMutation } =
	clientsApi as any;
