import { IdentificationIcon } from '@heroicons/react/solid';
import { AppRoute } from 'domain/routes/route.type';
import { lazy } from 'react';

const SignInPage = lazy(() => import('../pages/SignIn'));
const SignUpPage = lazy(() => import('../pages/SignUp'));
const VerifyAccountPage = lazy(() => import('../pages/VerifyAccount'));
const RecoverPasswordPage = lazy(() => import('../pages/RecoverPassword'));
const ResetPasswordValidatePage = lazy(() => import('../pages/ResetPassword'));

export const routes: AppRoute[] = [
	{
		id: 'SignIn',
		name: 'SignIn',
		path: '/',
		icon: <IdentificationIcon className={'text-light-primary-green w-6 h-6'} />,
		page: SignInPage,
		breadcrumb: ['SignIn'],
	},
	{
		id: 'SignUp',
		name: 'SignUp',
		path: '/signup',
		icon: <IdentificationIcon className={'text-light-primary-green w-6 h-6'} />,
		page: SignUpPage,
		breadcrumb: ['SignUp'],
	},
	{
		id: 'VerifyAccount',
		name: 'verifyaccount',
		path: '/verify_account',
		icon: <IdentificationIcon className={'text-light-primary-green w-6 h-6'} />,
		page: VerifyAccountPage,
		breadcrumb: ['VeryfyAccount'],
	},
	{
		id: 'RecoverPassword',
		name: 'recover_password',
		path: '/recover_password',
		icon: <IdentificationIcon className={'text-light-primary-green w-6 h-6'} />,
		page: RecoverPasswordPage,
		breadcrumb: ['RecoverPassword'],
	},
	{
		id: 'ResetPassword',
		name: 'reset_password',
		path: '/reset_password',
		icon: <IdentificationIcon className={'text-light-primary-green w-6 h-6'} />,
		page: ResetPasswordValidatePage,
		breadcrumb: ['ResetPassword'],
	},
];
