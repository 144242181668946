import { ShoppingBagIcon, TagIcon } from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const ProductListPage = lazy(() => import('../pages/ProductList'));
const EditProductPage = lazy(() => import('../pages/EditProduct'));
const AddProductPage = lazy(() => import('../pages/AddProduct'));

const CategoriesListPage = lazy(() => import('../pages/CategoriesList'));
const EditCategoryPage = lazy(() => import('../pages/EditCategory'));
const AddCategoryPage = lazy(() => import('../pages/AddCategory'));

export const routes: AppRoute[] = [
	{
		id: pageIds.products,
		name: 'Productos',
		path: '/Inventory',
		icon: <ShoppingBagIcon className={'text-light-primary-green w-6 h-6'} />,
		page: ProductListPage,
		breadcrumb: ['Inventory'],
		showInMenu: true,
		isPrivate: true,
	},
	{
		id: 'EditProduct',
		name: 'Edit Product',
		path: '/Inventory/edit/:id',
		icon: <ShoppingBagIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditProductPage,
		breadcrumb: ['Clients', 'Edit Product'],
		isPrivate: true,
	},
	{
		id: 'AddProduct',
		name: 'Add Product',
		path: '/Inventory/add',
		icon: <ShoppingBagIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddProductPage,
		breadcrumb: ['Inventory', 'Add product'],
		isPrivate: true,
	},
	{
		id: pageIds.productsCategories,
		name: 'Categories',
		path: '/Inventory/categories',
		icon: <TagIcon className={'text-light-primary-green w-6 h-6'} />,
		page: CategoriesListPage,
		breadcrumb: ['Inventory', 'Categories'],
		showInMenu: true,
		isPrivate: true,
	},
	{
		id: pageIds.productsCategories,
		name: 'Add Category',
		path: '/Inventory/categories/add',
		icon: <TagIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddCategoryPage,
		breadcrumb: ['Inventory', 'Add Categories'],
		isPrivate: true,
	},
	{
		id: pageIds.productsCategories,
		name: 'Edit Category',
		path: '/Inventory/categories/edit/:id',
		icon: <TagIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditCategoryPage,
		breadcrumb: ['Inventory', 'Edit Categories'],
		isPrivate: true,
	},
];
