import { CalculatorIcon } from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const InvoicesListPage = lazy(() => import('../pages/InvoicesList'));
const EditInvoicesPage = lazy(() => import('../pages/EditInvoices'));
const AddInvoicesPage = lazy(() => import('../pages/AddInvoices'));

export const routes: AppRoute[] = [
	{
		id: pageIds.proforms,
		name: 'ProFormas',
		path: '/proforms',
		icon: <CalculatorIcon className={'text-light-primary-green w-6 h-6'} />,
		page: InvoicesListPage,
		breadcrumb: ['ProForms'],
		showInMenu: true,
		isPrivate: true,
	},
	{
		// @TODO `${pageIds.invoices}-EDIT`
		id: 'EditProForms',
		name: 'Edit ProForms',
		path: '/proforms/edit/:id',
		icon: <CalculatorIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditInvoicesPage,
		breadcrumb: ['Invoices', 'Edit Proforms'],
		isPrivate: true,
	},
	{
		id: 'AddInvoices',
		name: 'Add Invoices',
		path: '/proforms/add',
		icon: <CalculatorIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddInvoicesPage,
		breadcrumb: ['Proforms', 'Add Proforms'],
		isPrivate: true,
	},
];
