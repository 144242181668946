import {
	CashIcon,
	LibraryIcon,
	LinkIcon,
	OfficeBuildingIcon,
} from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { Permissions } from 'domain/Permissions';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const BudgetsListPage = lazy(() => import('../pages/BudgetsList'));
const EditBudgetsPage = lazy(() => import('../pages/EditBudgets'));
const AddBudgetsPage = lazy(() => import('../pages/AddBudgets'));

export const routes: AppRoute[] = [
	{
		id: pageIds.impimur,
		name: 'Impimur',
		path: '/impimur',
		icon: <CashIcon className={'text-light-primary-green w-6 h-6'} />,
		page: BudgetsListPage,
		breadcrumb: ['Impimur'],
		permissions: [Permissions.impimur.read],
		showInMenu: true,
		isPrivate: true,
	},
	{
		id: 'EditBudgets',
		name: 'Edit Budgets',
		path: '/impimur/edit/:id',
		icon: <CashIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditBudgetsPage,
		breadcrumb: ['Impimur', 'Edit Impimur'],
		permissions: [Permissions.impimur.update],
		isPrivate: true,
	},
	{
		id: 'AddBudgets',
		name: 'Add Budgets',
		path: '/impimur/add',
		icon: <CashIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddBudgetsPage,
		breadcrumb: ['Impimur', 'Add Impimur'],
		permissions: [Permissions.impimur.create],
		isPrivate: true,
	},
];
