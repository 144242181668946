import { createSlice } from '@reduxjs/toolkit';
import { IEnterpriseSlice } from './type';

export const initialState: IEnterpriseSlice = {
	_id: '',
	name: '',
	NIF: '', // Fiscal identifier number
	email: '',
	phoneNumber: '',
	website: '',
	contactType: '',
	logo:
		'https://lolagestion.com/wp-content/uploads/2022/05/cropped-Favi_Insta-192x192.png',
};

export const EnterpriseSlice = createSlice({
	name: 'enterprise',
	initialState,
	reducers: {
		clearEnterpiseState: () => initialState,
		updateEnterpiseState: (state, action) => {
			return {
				...state,
				...action.payload,
			};
		},
	},
});

export default EnterpriseSlice.reducer;
export const { updateEnterpiseState, clearEnterpiseState } =
	EnterpriseSlice.actions;
