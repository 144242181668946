import { PlusIcon } from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const AddItemsPage = lazy(() => import('../pages/AddItems'));

export const routes: AppRoute[] = [
	{
		id: pageIds.items,
		name: 'Items',
		path: '/items/add',
		icon: <PlusIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddItemsPage,
		breadcrumb: ['Items', 'Add Items'],
		isPrivate: true,
		showInMenu: true,
	},
];
