import { UsersIcon } from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { Permissions } from 'domain/Permissions';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const ClientsListPage = lazy(() => import('../pages/ClientsList'));
const EditClientsPage = lazy(() => import('../pages/EditClients'));
const AddClientsPage = lazy(() => import('../pages/AddClients'));

export const routes: AppRoute[] = [
	{
		id: pageIds.clients,
		name: 'Clientes',
		path: '/Clients',
		icon: <UsersIcon className={'text-light-primary-green w-6 h-6'} />,
		page: ClientsListPage,
		breadcrumb: ['Clients'],
		permissions: [Permissions.clients.read],
		showInMenu: true,
		isPrivate: true,
	},
	{
		id: 'EditClients',
		name: 'Edit Clients',
		path: '/Clients/edit/:id',
		icon: <UsersIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditClientsPage,
		breadcrumb: ['Clients', 'Edit Client'],
		permissions: [Permissions.clients.create],
		isPrivate: true,
	},
	{
		id: 'AddClients',
		name: 'Add Clients',
		path: '/Clients/add',
		icon: <UsersIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddClientsPage,
		breadcrumb: ['Clients', 'Add Client'],
		permissions: [Permissions.clients.create],
		isPrivate: true,
	},
];
