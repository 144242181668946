import { RouteObject } from 'react-router-dom';
import { CommonPageLayout } from 'Layouts';
import { AppRoute } from './route.type';
import { RequiredAuthentication } from 'providers/RequiredAuthentication';

export const mapAppRouteToReactRouterDomRoute = (
	appRoute: AppRoute
): RouteObject => ({
	path: appRoute.path,
	element: appRoute.isPrivate ? (
		<RequiredAuthentication>
			<CommonPageLayout>
				<appRoute.page />
			</CommonPageLayout>
		</RequiredAuthentication>
	) : (
		<appRoute.page />
	),
});
