import {
	CashIcon,
	LibraryIcon,
	LinkIcon,
	OfficeBuildingIcon,
} from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { Permissions } from 'domain/Permissions';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const BudgetsListPage = lazy(() => import('../pages/BudgetsList'));
const EditBudgetsPage = lazy(() => import('../pages/EditBudgets'));
const AddBudgetsPage = lazy(() => import('../pages/AddBudgets'));

export const routes: AppRoute[] = [
	{
		id: pageIds.FND,
		name: 'FND',
		path: '/FND',
		icon: <CashIcon className={'text-light-primary-green w-6 h-6'} />,
		page: BudgetsListPage,
		breadcrumb: ['Budgets'],
		permissions: [Permissions.FND.read],
		showInMenu: true,
		isPrivate: true,
	},
	{
		id: 'EditBudgetsFND',
		name: 'Edit Budgets',
		path: '/FND/edit/:id',
		icon: <CashIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditBudgetsPage,
		breadcrumb: ['Budgets', 'Edit Budgets'],
		permissions: [Permissions.FND.update],
		isPrivate: true,
	},
	{
		id: 'AddBudgetsFND',
		name: 'Add Budgets',
		path: '/FND/add',
		icon: <CashIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddBudgetsPage,
		breadcrumb: ['Budgets', 'Add Budgets'],
		permissions: [Permissions.FND.create],
		isPrivate: true,
	},
];
