import { AppRoute } from 'domain/routes/route.type';
import { FC, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setBreadCrumb } from 'redux/slices/breadcrumb';
import { SidebarIcon } from '../../atoms/Sidebar/SidebarIcon';
import { SidebarLabel } from '../../atoms/Sidebar/SidebarLabel';

interface SidebarItemTypes {
	gap: boolean | undefined;
	open: boolean;
	route: AppRoute;
	isChild?: boolean;
	setBreadCrumb: (bc: string[]) => void;
}

export const SidebarItem: FC<SidebarItemTypes> = ({
	route,
	gap,
	open,
	setBreadCrumb,
	isChild,
}) => {
	if (!open && isChild)
		return (
			<NavLink
				className={`flex rounded-md p-1 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-3 `}
				to={route.path!}
			>
				<SidebarIcon icon={route.icon} />
			</NavLink>
		);
	return (
		<NavLink
			to={route.path!}
			className={`flex  rounded-md p-1 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-3
								${gap ? 'mt-6' : 'mt-2'}  ${
				open && 'hover:bg-light-primary-transparentGreen duration-200'
			} `}
			onClick={() => setBreadCrumb(route.breadcrumb)}
		>
			<SidebarIcon icon={route.icon} />
			<SidebarLabel title={route.name} open={open} />
		</NavLink>
	);
};
