import { UsersIcon, TruckIcon } from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const DriversListPage = lazy(() => import('../pages/DriversList'));
const FloatsListPage = lazy(() => import('../pages/FloatsList'));
/* const EditPurchasesPage = lazy(() => import('../pages/EditPurchases'));
const AddPurchasesPage = lazy(() => import('../pages/AddPurchases')); */

export const routes: AppRoute[] = [
	{
		id: pageIds.drivers,
		name: 'Drivers',
		path: '/logistic/drivers',
		icon: <UsersIcon className={'text-light-primary-green w-6 h-6'} />,
		page: DriversListPage,
		breadcrumb: ['Drivers'],
		showInMenu: true,
		isPrivate: true,
	},
	{
		id: pageIds.floats,
		name: 'Floats',
		path: '/logistic/floats',
		icon: <TruckIcon className={'text-light-primary-green w-6 h-6'} />,
		page: FloatsListPage,
		breadcrumb: ['Drivers'],
		showInMenu: true,
		isPrivate: true,
	},
	/* {
		// @TODO `${pageIds.Purchases}-EDIT`
		id: 'EditPurchases',
		name: 'Edit Purchases',
		path: '/purchases/edit/:id',
		icon: <CalculatorIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditPurchasesPage,
		breadcrumb: ['Purchases', 'Edit Purchases'],
		isPrivate: true,
	},
	{
		id: 'AddPurchases',
		name: 'Add Purchases',
		path: '/purchases/add',
		icon: <CalculatorIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddPurchasesPage,
		breadcrumb: ['Purchases', 'Add Purchases'],
		isPrivate: true,
	}, */
];
