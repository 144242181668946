import { FC } from 'react';

interface SidebarIconsTypes {
	title: string;
	open: boolean;
}

export const SidebarLabel: FC<SidebarIconsTypes> = ({ title, open }) => {
	return (
		<span className={`${!open && 'hidden'} text-sm origin-left duration-200`}>
			{title}
		</span>
	);
};
