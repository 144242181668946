export const Permissions = {
	users: {
		create: 'users/create',
		read: 'users/read',
		update: 'users/update',
		delete: 'users/delete',
	},
	clients: {
		create: 'clients/create',
		read: 'clients/read',
		update: 'clients/update',
		delete: 'clients/delete',
	},
	impimur: {
		create: 'impimur/create',
		read: 'impimur/read',
		update: 'impimur/update',
		delete: 'impimur/delete',
	},
	FND: {
		create: 'FND/create',
		read: 'FND/read',
		update: 'FND/update',
		delete: 'FND/delete',
	},
};
