import {
	LocationMarkerIcon,
	MapIcon,
	NewspaperIcon,
	ExclamationIcon,
	ShoppingCartIcon,
	CollectionIcon,
	BookOpenIcon,
} from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const AddLocalityPage = lazy(() => import('../pages/AddLocality'));
const AddReferencesPage = lazy(() => import('../pages/AddRefence'));
const SpecialReferencesPage = lazy(() => import('../pages/SpecialReference'));
const AddProductsPage = lazy(() => import('../pages/AddProduct'));
const ProductListPage = lazy(() => import('../pages/ProductList'));
const EditProductPage = lazy(() => import('../pages/EditProduct'));
const IncidencesListPage = lazy(() => import('../pages/IncidencesList'));
const SalesPage = lazy(() => import('../pages/Sales'));
const ContractsPage = lazy(() => import('../pages/Contracts'));
const ContractsEditPage = lazy(() => import('../pages/ContractsEdit'));

export const routes: AppRoute[] = [
	{
		id: pageIds.locality,
		name: 'Localities',
		path: '/locality',
		icon: <LocationMarkerIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddLocalityPage,
		breadcrumb: ['Locality'],
		isPrivate: true,
		showInMenu: true,
	},
	{
		id: 'references',
		name: 'References',
		icon: <NewspaperIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddLocalityPage,
		breadcrumb: ['Locality'],
		isParent: true,
		isPrivate: true,
		showInMenu: true,
		children: [
			{
				id: pageIds.references,
				name: 'References',
				path: '/references',
				page: AddReferencesPage,
				breadcrumb: ['References'],
				isPrivate: true,
				showInMenu: true,
				icon: <BookOpenIcon className={'text-light-primary-green w-4 h-4'} />,
			},
			{
				id: pageIds.specialReferences,
				name: 'Special Ref.',
				path: '/special_references',
				page: SpecialReferencesPage,
				breadcrumb: ['References'],
				isPrivate: true,
				showInMenu: true,
				icon: <BookOpenIcon className={'text-light-primary-green w-4 h-4'} />,
			},
		],
	},
	{
		id: pageIds.productsPubliApp,
		name: 'Products',
		path: '/ProductsPubli',
		icon: <MapIcon className={'text-light-primary-green w-6 h-6'} />,
		page: ProductListPage,
		breadcrumb: ['ProductsPubli'],
		isPrivate: true,
		showInMenu: true,
	},
	{
		id: pageIds.productsPubliApp,
		name: 'Products',
		path: '/ProductsPubli/create',
		icon: <MapIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddProductsPage,
		breadcrumb: ['ProductsPubli'],
		isPrivate: true,
		showInMenu: false,
	},
	{
		id: pageIds.productsPubliApp,
		name: 'Products',
		path: '/ProductsPubli/edit/:id',
		icon: <MapIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditProductPage,
		breadcrumb: ['ProductsPubli'],
		isPrivate: true,
		showInMenu: false,
	},
	{
		id: pageIds.incidencesPubliApp,
		name: 'Incidences',
		path: '/incidencesPubli',
		icon: <ExclamationIcon className={'text-light-primary-green w-6 h-6'} />,
		page: IncidencesListPage,
		breadcrumb: ['IncidencesPubli'],
		isPrivate: true,
		showInMenu: true,
	},
	{
		id: pageIds.salesPubliApp,
		name: 'Sales',
		path: '/salesPubli',
		icon: <ShoppingCartIcon className={'text-light-primary-green w-6 h-6'} />,
		page: SalesPage,
		breadcrumb: ['SalesPubliApp'],
		isPrivate: true,
		showInMenu: true,
	},
	{
		id: pageIds.productsPubliApp,
		name: 'Contracts',
		path: '/contractsPubli',
		icon: <CollectionIcon className={'text-light-primary-green w-6 h-6'} />,
		page: ContractsPage,
		breadcrumb: ['contractsPubli'],
		isPrivate: true,
		showInMenu: true,
	},
	{
		id: pageIds.productsPubliApp,
		name: 'Contracts',
		path: '/contractsPubli/create',
		icon: <CollectionIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddProductsPage,
		breadcrumb: ['contractsPubli'],
		isPrivate: true,
		showInMenu: false,
	},
	{
		id: pageIds.productsPubliApp,
		name: 'Contracts',
		path: '/contractsPubli/edit/:id',
		icon: <CollectionIcon className={'text-light-primary-green w-6 h-6'} />,
		page: ContractsEditPage,
		breadcrumb: ['contractsPubli'],
		isPrivate: true,
		showInMenu: false,
	},
];
