import { IdentificationIcon } from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { Permissions } from 'domain/Permissions';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const UserListPage = lazy(() => import('../pages/UsersList'));
const EditUserPage = lazy(() => import('../pages/EditUser'));
const AddUserPage = lazy(() => import('../pages/AddUser'));

export const routes: AppRoute[] = [
	{
		id: pageIds.users,
		name: 'Usuarios',
		path: '/users',
		icon: <IdentificationIcon className={'text-light-primary-green w-6 h-6'} />,
		page: UserListPage,
		breadcrumb: ['Users'],
		showInMenu: true,
		isPrivate: true,
		permissions: [Permissions.users.read],
	},
	{
		id: 'EditUser',
		name: 'Edit user',
		path: '/users/:id/edit',
		icon: <IdentificationIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditUserPage,
		breadcrumb: ['Users', 'Edit user'],
		isPrivate: true,
		permissions: [Permissions.users.update],
	},
	{
		id: 'AddUser',
		name: 'Add user',
		path: '/users/add',
		icon: <IdentificationIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddUserPage,
		breadcrumb: ['Users', 'Add user'],
		permissions: [Permissions.users.create],
		isPrivate: true,
	},
];
