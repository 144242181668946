import { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectAuthState } from 'redux/slices/authentication/selectors';

export const RequiredAuthentication: FC<PropsWithChildren<any>> = ({
	children,
}) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { userIsAuthenticated, enterpriseId, isLoading } =
		useSelector(selectAuthState);

	useEffect(() => {
		if (
			!isLoading &&
			userIsAuthenticated &&
			!enterpriseId &&
			location.pathname !== '/bussines/add'
		) {
			console.log('Create an enterprise ');
			navigate('/bussines/add');
		}
	}, [userIsAuthenticated, location.pathname]);

	return <>{children}</>;
};
