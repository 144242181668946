import { mapAppRouteToReactRouterDomRoute } from 'domain/routes/route.mapper';
import { RouteObject } from 'react-router-dom';
import { Enterprise } from 'redux/slices/enterprises/type';
import { hasPermissions } from 'utils/permissions';
import { AppModules } from '../config/modules';
import { AppRoute } from '../domain/routes/route.type';

export const getAppRoutes = (
	requiredAuth: boolean,
	permissions: any,
	enterprise: Enterprise
): RouteObject[] => {
	const getRoutesFromModuleName = (moduleName: string): AppRoute[] | null => {
		try {
			const { routes } = require(`ui/${moduleName}/routes`) as {
				routes: AppRoute[];
			};
			return routes;
		} catch (e) {
			console.error(`Can not load routes from module ${moduleName}`);
			return null;
		}
	};

	const mapAppRoutes = (userPermissions: any): RouteObject[] => {
		let appRoutes: AppRoute[] = [];
		for (const element of AppModules) {
			const moduleName = element;
			const routes = getRoutesFromModuleName(moduleName);
			if (!routes) continue;
			const routesWithChildren = [];
			for (const route of routes) {
				if (route.children) {
					routesWithChildren.push(...route.children);
				} else {
					routesWithChildren.push(route);
				}
			}
			let routesFromAuthState = routesWithChildren.filter(
				route =>
					!!route.isPrivate === requiredAuth &&
					(!route.permissions ||
						hasPermissions(route.permissions, userPermissions, enterprise)) &&
					!route.isParent
			);

			appRoutes.push(...routesFromAuthState);
		}
		return appRoutes.map(mapAppRouteToReactRouterDomRoute);
	};

	return mapAppRoutes(permissions);
};
