import { CalculatorIcon } from '@heroicons/react/solid';
import { pageIds } from 'config/menu/pageIds';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const InvoicesListPage = lazy(() => import('../pages/InvoicesList'));
const EditInvoicesPage = lazy(() => import('../pages/EditInvoices'));
const AddInvoicesPage = lazy(() => import('../pages/AddInvoices'));

export const routes: AppRoute[] = [
	{
		id: pageIds.invoices,
		name: 'Facturas',
		path: '/invoices',
		icon: <CalculatorIcon className={'text-light-primary-green w-6 h-6'} />,
		page: InvoicesListPage,
		breadcrumb: ['Invoices'],
		showInMenu: true,
		isPrivate: true,
	},
	{
		// @TODO `${pageIds.invoices}-EDIT`
		id: 'EditInvoices',
		name: 'Edit Invoices',
		path: '/invoices/edit/:id',
		icon: <CalculatorIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditInvoicesPage,
		breadcrumb: ['Invoices', 'Edit Invoices'],
		isPrivate: true,
	},
	{
		id: 'AddInvoices',
		name: 'Add Invoices',
		path: '/invoices/add',
		icon: <CalculatorIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddInvoicesPage,
		breadcrumb: ['Invoices', 'Add Invoices'],
		isPrivate: true,
	},
];
