import { Menu } from '@headlessui/react';
import { FC } from 'react';

interface NotificationsItemTypes {
	title: string;
	text?: string;
}

export const NotificationsItem: FC<NotificationsItemTypes> = ({
	title,
	text,
}) => {
	return (
		<Menu.Item>
			{({ active }) => (
				<div
					className={`${
						active
							? 'bg-light-primary-softGreen text-light-primary-darkGreen'
							: 'text-gray-900'
					} group flex items-start rounded-md px-2 py-2 text-sm flex-col w-full duration-200 cursor-pointer`}
				>
					<h3 className='font-semibold text-sm'>{title}</h3>
					{text && <p className='font-light text-xs'>{text}</p>}
				</div>
			)}
		</Menu.Item>
	);
};
