import {
	LibraryIcon,
	LinkIcon,
	OfficeBuildingIcon,
} from '@heroicons/react/solid';
import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const BanksListPage = lazy(() => import('../pages/BanksList'));
const EditBanksPage = lazy(() => import('../pages/EditBanks'));
const AddBanksPage = lazy(() => import('../pages/AddBanks'));

export const routes: AppRoute[] = [
	{
		id: 'BanksList',
		name: 'Banks',
		path: '/banks',
		icon: <LibraryIcon className={'text-light-primary-green w-6 h-6'} />,
		page: BanksListPage,
		breadcrumb: ['Banks'],
		showInMenu: false,
		isPrivate: true,
	},
	{
		id: 'EditBanks',
		name: 'Edit Banks',
		path: '/banks/edit',
		icon: <LibraryIcon className={'text-light-primary-green w-6 h-6'} />,
		page: EditBanksPage,
		breadcrumb: ['Banks', 'Edit Banks'],
		isPrivate: true,
	},
	{
		id: 'AddBanks',
		name: 'Add Banks',
		path: '/banks/add',
		icon: <LibraryIcon className={'text-light-primary-green w-6 h-6'} />,
		page: AddBanksPage,
		breadcrumb: ['Banks', 'Add Banks'],
		isPrivate: true,
	},
];
