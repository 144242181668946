import React, { FC } from 'react';
import { ButtonFullScreen } from '../../atoms/Bottons/ButtonFullScreen';
import { DropdowNotifications } from '../../molecules/DropdownNotifications';
import { DropdownUser } from '../../molecules/DropdowUser';
import { DropdownSettings } from '../../molecules/DropdownSettings';
import { SearchBox } from '../../molecules/SearchBox';

interface NavbarPropTypes {
	onLogout: () => void;
	logo?: string;
}

export const Navbar: FC<NavbarPropTypes> = ({ onLogout, logo }) => {
	return (
		<div className='w-full p-4 bg-light-primary-white drop-shadow-xl rounded-lg mb-8 z-50'>
			<div className='flex flex-row w-full'>
				<div className='flex flex-col items-start justify-center  w-1/3'>
					{/* <SearchBox
						light={false}
						placeholder='Search for results...'
						classes='w-full'
					/> */}
				</div>
				<div className='flex flex-col items-end justify-center w-full '>
					<div className='flex flex-row gap-4'>
						<ButtonFullScreen />
						<DropdowNotifications />
						<DropdownSettings />
						<DropdownUser onLogout={onLogout} enterpriseLogo={logo} />
					</div>
				</div>
			</div>
		</div>
	);
};
