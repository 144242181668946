import { lazy } from 'react';
import { AppRoute } from '../../../domain/routes/route.type';

const Private = lazy(() => import('../pages/PrivateNotFound'));
const Public = lazy(() => import('../pages/PublicNotFound'));

export const routes: AppRoute[] = [
	{
		id: 'NotFoundPrivate',
		name: 'NotFound',
		path: '*',
		icon: <></>,
		page: Private,
		breadcrumb: [],
		isPrivate: true,
	},
	{
		id: 'NotFoundPublic',
		name: 'NotFound',
		path: '*',
		icon: <></>,
		page: Public,
		breadcrumb: [],
	},
];
