import { BrowserRouter as Router } from 'react-router-dom';
import { AuthenticationProvider } from './providers/authentication';
import { AppRouter } from './router';

const App = (): JSX.Element => {
	return (
		<AuthenticationProvider>
			<Router>
				<AppRouter />
			</Router>
		</AuthenticationProvider>
	);
};

export default App;
